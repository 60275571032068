export default function NotFound() {
  return (
    <section className="hero is-medium">
      <div className="hero-body">
        <p className="title has-text-centered">
          Invalid Page
        </p>
      </div>
    </section>
  )
}   