import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { userState, isLoggedInState } from './auth';
import { useNavigate } from 'react-router-dom';
import { logout } from './auth';

function NavLoggedIn() {
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInState);
  const [user] = useRecoilState(userState);
  const navigate = useNavigate();
  const onClickLogout = () => {
    logout()
    setIsLoggedIn(false);
    navigate('/login');
  }

  if (!isLoggedIn) return <div className="navbar-end"></div>

  return (
    <div className="navbar-end">
      <div className="navbar-item has-dropdown is-hoverable">
        <p className="navbar-link"> {user.username} </p>

        <div className="navbar-dropdown">
          <Link className="navbar-item">
            Change Password
          </Link>
        </div>
      </div>
      <div className="navbar-item">
        <div className="buttons">
          <button className="button is-light" onClick={onClickLogout}>Log out</button>
        </div>
      </div>
    </div>
  )
}

export default function Nav() {
  return (
    <nav className="navbar is-spaced has-shadow" role="navigation" aria-label="main navigation">
      <div className="navbar-brand mr-5">
        <Link to="/" className="navbar-item is-size-4">
          Theme
        </Link>
      </div>
      <div className="navbar-start">
        <Link to="/" className="navbar-item">
          Home
        </Link>
        <Link className="navbar-item">
          Result
        </Link>
      </div>

      <NavLoggedIn />
    </nav>
  )
}