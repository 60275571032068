import { useState, useCallback, useEffect } from "react";
import StockColumn from "./StockColumn";
import StockChart from "../StockChart";
import { useModal } from "../TradeModal";
import { fetchStockIndex } from "../apis";

export default function ThemeWindow({theme, onClickDelete, onClickTitle}) {
  const { openBuyModal } = useModal();
  const [si, setSI] = useState(theme.default_si)

  useEffect(() => {
    setSI(theme.default_si)
  }, [theme.default_si])

  const onClickStock = useCallback(si_id => {
    fetchStockIndex(si_id)
    .then(si => setSI(si))
  }, [setSI])
  
  return (
    <div className="columns">
      <div className="column is-4">
        <div className="is-flex p-1">
          <h2 className="is-flex-grow-1" style={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>
            <span style={{cursor: "pointer", fontWeight: "bold"}} onClick={() => onClickTitle(theme.keyword_id)}>
              {!!theme.fixed ? `📌 ${theme.keyword_name}` : theme.keyword_name}
            </span>
          </h2>
          <button className="button is-warning is-small" onClick={() => onClickDelete(theme.keyword_id)}>X</button>
        </div>
        {
          !si ? <div>Loading...</div> : 
          <>
            <StockColumn stocks={theme.stocks} onClickStock={onClickStock} selected_si={si} />
            <button className="button is-danger is-fullwidth mt-1" onClick={() => openBuyModal(si.id)}> 매수 </button>
          </>
        }
      </div>
      {
        !si ? <div></div> : 
        <div className="column" style={{minWidth: 0, paddingLeft: 0, paddingRight: 0}}>
          <StockChart si={si}/>
        </div>
      }
    </div>
  );
}