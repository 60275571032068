import React, { useCallback, useEffect, useState } from 'react';
import ResultColumn from './Result/ResultColumn';
import SearchColumn from './Search/SearchColumn';
import ThemeColumn from './Theme/ThemeColumn';
import ThemeSettingButtons from './Theme/ThemeSettingButtons';

import { useRecoilState } from 'recoil';
import { isLoggedInState } from './auth';
import { Navigate } from 'react-router-dom';
import { fetchCaptures, fetchResults, fetchSearches } from './apis';
import TradeModal, { useModal } from './TradeModal';
import SettingModal from './SettingModal';
import { saveExcludeIdMap, loadIndexMap, saveIndexMap, loadPriorities, savePriorities, loadExcludeIdMap, dateToDateString } from './utils';
import { socket } from './socket';


function reorderThemes(themes, index_map) {
  const res = new Array(6)
  themes.forEach(theme => {
    theme.fixed = false
    const index = index_map.get(theme.keyword_id)
    if ((!!index || index === 0) && !res[index]) {
      theme.fixed = true
      res[index] = theme
    }
  })

  let index = 0
  themes.forEach(theme => {
    if (!theme.fixed) {
      while (!!res[index]) {
        index++
      }
      res[index] = theme
    }
  })

  return res
}

async function _fetchCaptures(exclude_ids, include_ids) {
  try {
    const response = await fetchCaptures(exclude_ids, include_ids)
    return response.slice(0, 6)
  } catch (error) {
    console.error(error)
  }
}

/**
 * @param {Array} data
 * @param {Array} themes
 */
function isNeedUpdate(data, themes) {
  // 대전제: Fetch 전후가 같으면 안해도 됨
  // 고정된 애들은 종목끼리는 위치 상관없이 코드만 같으면 됨
  // 고정 안된 애들 끼리는 위치 순서 비교하고 코드 같으면 됨
  const dataMap = new Map(data.map(theme => [theme.keyword_id, theme]))
  const fixedThemes = themes.filter(theme => theme.fixed)
  const isSameTheme = theme => {
    const newData = dataMap.get(theme.keyword_id)
    return !!newData && newData.stocks.toString() === theme.stocks.map(stock => stock.code).toString()
  }

  if (!fixedThemes.every(isSameTheme)) return true;

  const fixedThemeIdSet = new Set(themes.filter(theme => theme.fixed).map(theme => theme.keyword_id))
  const nonFixedThemes = themes.filter(theme => !theme.fixed)
  const nonFixedDatas = data.filter(theme => !fixedThemeIdSet.has(theme.keyword_id))
  const nonFixedThemeIds = nonFixedThemes.map(theme => theme.keyword_id)
  const nonFixedDataIds = nonFixedDatas.slice(0, nonFixedThemeIds.length).map(theme => theme.keyword_id)

  if (nonFixedDataIds.toString() !== nonFixedThemeIds.toString()) return true;

  return !nonFixedThemes.every(isSameTheme)
}



export default function Main() {
  const {closeModalIfMatch} = useModal()
  const [isLoggedIn] = useRecoilState(isLoggedInState);
  const [results, setResults] = useState([]);
  const [searches, setSearches] = useState([]);
  const [priorities, setPriorities] = useState(loadPriorities(new Date()))
  const [exclude_id_map, setExcludeIdMap] = useState(loadExcludeIdMap(new Date()));
  const [themes, setCaptures] = useState([]);
  const [index_map, setIndexMap] = useState(loadIndexMap(new Date()));

  const setThemeIndex = useCallback((id, index) => {
    const new_index_map = new Map(index_map)
    new_index_map.set(id, index)
    saveIndexMap(new_index_map, new Date())
    setIndexMap(new_index_map)
  }, [index_map])

  const unsetThemeIndex = useCallback((id) => {
    const new_index_map = new Map(index_map)
    new_index_map.delete(id)
    saveIndexMap(new_index_map, new Date())
    setIndexMap(new_index_map)
  }, [index_map])

  const setThemeExclude = useCallback((id, name) => {
    const new_exclude_map = new Map(exclude_id_map)
    new_exclude_map.set(id, name)
    saveExcludeIdMap(new_exclude_map, new Date())
    setExcludeIdMap(new_exclude_map)
    unsetThemeIndex(id)
  }, [exclude_id_map, unsetThemeIndex, setExcludeIdMap])

  const updateThemes = useCallback(async () => {
    return _fetchCaptures([...exclude_id_map.keys()], [...index_map.keys()])
    .then(themes => {
      console.log(themes)
      setCaptures(reorderThemes(themes, index_map))
    })
  }, [exclude_id_map, index_map])

  useEffect(() => {
    socket.on("themes", async data => {
      if (isNeedUpdate(data, themes)) {
        console.log("Need to update")
        await updateThemes()
        return;
      }
      console.log("No need to update")
    })
    return () => {
      socket.off("themes")
    }
  }, [updateThemes, themes])

  useEffect(() => {
    updateThemes()
    return () => {
      console.log("cleanup")
    }
  }, [updateThemes])

  const updateResults = useCallback(async () => {
    try {
      const today = dateToDateString(new Date())
      const results = await fetchResults(today, today)
      setResults(results)
    } catch (error) {
      console.error(error)
    }
  }, [])

  useEffect(() => {
    updateResults()
    return () => {}
  }, [])

  const sortSearches = useCallback(searches => {
    searches = searches.map(search => {
      const priority = priorities.get(search.si_id)
      search.priority = (priority === undefined ? '1' : priority) 
      return search
    })
    searches.sort((a, b) => {
      if (a.priority !== b.priority) {
        return Number(b.priority) - Number(a.priority)
      } else {
        return new Date(b.dt) - new Date(a.dt)
      }
    })
    return searches
  }, [priorities])

  useEffect(() => {
    fetchSearches()
      .then(setSearches)
      .catch(console.error)

    return () => {}
  }, [])

  const changePriority = useCallback((si_id, priority) => {
    setPriorities(priorities => {
      const newPriorities = new Map(priorities)
      newPriorities.set(si_id, priority)
      savePriorities(newPriorities, new Date())
      return newPriorities
    })
  }, [])

  const updateOnResultEvent = useCallback(result_id => {
    closeModalIfMatch(result_id)
    updateResults()
  }, [closeModalIfMatch, updateResults])

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Connected to server')
    })
    socket.on('disconnect', () => {
      console.log('Disconnected from server')
    })
    socket.on("test", data => {
      console.log(data)
    })
    socket.on("searches", setSearches)

    socket.connect()
    return () => {
      socket.disconnect()
    }
  }, [updateResults])

  useEffect(() => {
    socket.on("result", updateOnResultEvent)
    return () => {
      socket.off("result")
    }
  }, [updateOnResultEvent])

  return isLoggedIn ? (
    <React.Fragment>
      <SettingModal/>
      <TradeModal updateResults={updateResults}/>
      <section className="hero p-4">
        <div className="hero-head">
          <div className="columns">
            <div className="column">
              <ThemeColumn themes={themes} setThemeExclude={setThemeExclude} setThemeIndex={setThemeIndex} unsetThemeIndex={unsetThemeIndex} />
            </div>
            <div className="column is-4 is-fullheight">
              <div className="is-flex is-flex-direction-column" style={{ height: "100%" }}>
                <div>
                  <ThemeSettingButtons exclude_id_map={exclude_id_map} setExcludeIdMap={setExcludeIdMap}/>
                </div>
                <div style={{ height: 50 + 'vh' }}>
                  <SearchColumn searches={sortSearches(searches)} themes={themes} onChangeHandler={changePriority}/>
                </div>
                <div style={{ height: 30 + 'vh' }} className="is-flex-grow-1">
                  <ResultColumn updateResults={updateResults} results={results}/>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

    </React.Fragment>
  ) : <Navigate to="/login" />
}