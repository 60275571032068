function saveArray(date, key, arr) {
    localStorage.setItem(key, JSON.stringify({
        date: date.toLocaleDateString(),
        arr
    }))
}

function loadArray(date, key) {
    const data = JSON.parse(localStorage.getItem(key))
    if (data && data.date && data.date === date.toLocaleDateString()) {
        return data.arr
    } else {
        return []
    }
}

export function savePriorities(priorities, date) {
    saveArray(date, 'priorities', Array.from(priorities.entries()))
}

export function loadPriorities(date) {
    return new Map(loadArray(date, 'priorities'))
}

export function saveExcludeIdMap(excludeIdMap, date) {
    saveArray(date, 'excludeIdMap', Array.from(excludeIdMap.entries()))
}

export function loadExcludeIdMap(date) {
    return new Map(loadArray(date, 'excludeIdMap'))
}

export function saveIndexMap(indexMap, date) {
    saveArray(date, 'indexMap', Array.from(indexMap.entries()))
}

export function loadIndexMap(date) {
    return new Map(loadArray(date, 'indexMap'))
}

/**
 *
 * @param {Date} date
 * @returns
 */
export function dateToDateString(date) {
    return `${date.getFullYear().toString().padStart(4, "0")}\
-${(date.getMonth() + 1).toString().padStart(2, "0")}\
-${date.getDate().toString().padStart(2, "0")}`
}