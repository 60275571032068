import style from './ResultTableRow.module.css'

function makeProfitText(profit) {
  const isSuccess = profit > 1
  const color = isSuccess ? "red" : "blue"
  const sign = isSuccess ? "+" : ""
  return <span style={{ color: color }}>{sign}{(profit * 100 - 100).toFixed(2)}%</span>
}

function makeTimeFormat(dt) {
  return dt.slice(11, 16)
}

function makeBuyColumn(result, onClickCancelHandler) {
  if (!result.buy_executed_dt) {
    return <><span>{result.buy_price}</span><button className="button is-warning is-small" onClick={() => onClickCancelHandler(result.buy_id)}>취소</button></>
  }

  return <span>{result.buy_executed_price} ({makeTimeFormat(result.buy_executed_dt)})</span>
}

function makeSellColumn(result, onClickButtonHandler) {
  if (!result.sell_executed_dt) {
    return <>
      {
        !!result.sell_price ? <span>{result.sell_price} ({result.sell_count})</span> : <></>
      }
      <button className="button is-info is-small" onClick={() => onClickButtonHandler(result.result_id, !result.buy_executed_dt)}>매도</button>
    </>
  }

  if (!result.sell_executed_price) {
    return <span>매도중...</span>
  }

  const profitText = makeProfitText(result.sell_executed_price / result.buy_executed_price)
  return <span>{result.sell_executed_price} [{profitText}] ({makeTimeFormat(result.sell_executed_dt)})</span>
}

export default function ResultTableRow({ result, onClickButtonHandler, onClickCancelHandler }) {
  return (
    <tr className={style.row}>
      <td>{result.stock_name} ({result.stock_code})</td>
      <td>{makeBuyColumn(result, onClickCancelHandler)}</td>
      <td>{makeSellColumn(result, onClickButtonHandler)}</td>
    </tr>
  )
}