export default function ConfirmModal({children, closeModal, modalState, confirmModal, buttonMessage }) {
  if (!modalState) return null;

  return (
    <div className="modal is-active">
      <div className="modal-background" onClick={closeModal} />
      <div className="modal-content">
        <div className="box has-text-centered">
          {children}
          <button className="m-1 button is-success" onClick={() => confirmModal()}>{buttonMessage}</button>
          <button className="m-1 button" onClick={closeModal}>취소</button>
        </div>
      </div>
    </div>
  )
}