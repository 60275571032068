export default function StockColumn(props) {
  const onClickStock = stock => {
    props.onClickStock(stock.si_id)
  }

  const options = props.stocks.map(stock => 
    <button key={stock.code} className={stock.si_id === props.selected_si.id ? "button is-focused" : "button"} style={{
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",
      display: "inline-block",
      textAlign: "left",
    }} value={stock.code} onClick={() => onClickStock(stock)}>{stock.name}</button>
  )
  return (
    <div className="is-flex is-flex-direction-column">
      {options}
    </div>
  )
}