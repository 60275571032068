import { useModal } from "../TradeModal";
import SearchTableRow from "./SearchTableRow";

export default function SearchTable({searches, onChangeHandler, theme_si_set}) {
  const { openBuyModal } = useModal();

  const rows = searches.map(search => (
    <SearchTableRow
      key={search.code}
      search={search}
      onChangeHandler={onChangeHandler}
      onClickButtonHandler={openBuyModal}
      isInTheme={theme_si_set.has(search.si_id)}
    />
  ))
  return (
    <table id="index-table" className="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th>종목</th>
          <th className="has-text-centered">최초</th>
          <th className="has-text-centered">최종</th>
          <th className="has-text-centered">개수</th>
          <th className="has-text-centered">상태</th>
          <th></th>
        </tr>
      </thead>

      <tbody>
        {rows}
      </tbody>
    </table>
  )
}