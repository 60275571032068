import './App.css';
import "bulma/css/bulma.css";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Nav from './Nav';
import Main from './Main';
import Login from './Login';
import React from 'react';
import NotFound from './NotFound';

export default function App() {
  return (
    <Router>
      <Nav/>
      <Routes>
        <Route exact path="/" element={<Main/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </Router>
  )
}
