import ConfirmModal from "./ConfirmModal";
import { useCallback, useState } from "react";
import { useRecoilState, atom } from "recoil";
import { saveExcludeIdMap } from "./utils";

const modalState = atom({
  key: 'settingState',
  default: {
    isOpen: false,
    excludeIdMap: new Map(),
    setExcludeIdMap: () => {},
  }
});

export function useSettingModal() {
  const [modalDataState, setModalDataState] = useRecoilState(modalState);

  const closeModal = useCallback(
    () =>
      setModalDataState(() => {
        return { isOpen: false }
      }),
    [setModalDataState]
  );

  const openModal = useCallback(
    (excludeIdMap, setExcludeIdMap) => {
      setModalDataState({
        isOpen: true,
        excludeIdMap,
        setExcludeIdMap,
      })
    }, [setModalDataState]
  );

  return { modalDataState, closeModal, openModal };
}

export default function SettingModal() {
  const { modalDataState, closeModal } = useSettingModal();
  const [ includeIds, setIncludeIds ] = useState(new Set());

  const _closeModal = useCallback(() => {
    setIncludeIds(new Set())
    closeModal()
  }, [closeModal])

  const confirm = useCallback(() => {
    const newMap = new Map(modalDataState.excludeIdMap)
    includeIds.forEach(id => newMap.delete(id))

    saveExcludeIdMap(newMap, new Date())
    modalDataState.setExcludeIdMap(newMap)
    _closeModal()
  }, [modalDataState, includeIds, _closeModal])

  if (!modalDataState.isOpen) return

  return (
    <ConfirmModal closeModal={_closeModal} modalState={modalDataState} confirmModal={confirm} buttonMessage={'적용'}>
      <div className="is-flex is-flex-direction-column is-align-items-center">
        <p className="is-size-4">테마 추가</p>

        <div className="buttons m-4">
            {Array.from(modalDataState.excludeIdMap.entries()).map(([id, name]) => {
              return (
                <button key={id} className={"button " + (includeIds.has(id) ? "is-black" : "")} 
                onClick={() => {
                  if (includeIds.has(id)) {
                    includeIds.delete(id)
                  } else {
                    includeIds.add(id)
                  }
                  setIncludeIds(new Set(includeIds))
                }}>{name}</button>
              )
            })}
        </div>
      </div>
    </ConfirmModal>
  )
}