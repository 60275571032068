import ThemeWindow from './ThemeWindow';



function ThemeColumn({themes, setThemeExclude, setThemeIndex, unsetThemeIndex}) {
  const windows = themes.map((theme, index) => 
    <div key={theme.keyword_id} className="column is-half">
      <ThemeWindow 
        theme={theme}
        onClickDelete={id => {setThemeExclude(id, theme.keyword_name)}}
        onClickTitle={id => {
        if (!theme.fixed) {
          setThemeIndex(id, index)
        } else {
          unsetThemeIndex(id)
        }
      }}/>
    </div>
  )

  return (
    <div className="columns is-multiline">
      {windows}
    </div>
  );
}

export default ThemeColumn;