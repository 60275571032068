import React, {useRef, useEffect} from "react";
import { createChart, CrosshairMode } from "lightweight-charts";
import { socket } from "./socket";
import styles from "./StockChart.module.css";

function candlesToPrice(candles) {
  return candles.map(candle => {
    return {
      time: candle.time,
      open: candle.open,
      close: candle.close,
      high: candle.high,
      low: candle.low,
      customValues: {
        prev: candle.prev,
      }
    }
  })
}

function candlesToValue(candles) {
  return candles.map(candle => {
    return {
      time: candle.time,
      value: candle.value / 1_0000_0000,
      color: "rgba(0,0,0,0.5)"
    }
  })
}

function makePercent(value, prev) {
  return ((value - prev) / prev * 100).toFixed(2);
}


function makeTablePriceString(name, name_color, value, prev){
  const percent = makePercent(value, prev)
  const color = value > prev ? "red" : "blue"
  return `
  <tr>
    <td style="color: ${name_color}">${name}</td>
    <td style="color: ${color}">${value}</td>
    <td style="color: ${color}">${percent}%</td>
  </tr>`
}


export default function StockChart({si}) {
  const chartContainerRef = useRef();
  const chart = useRef();
  const tooltip = useRef();

  useEffect(() => {
    chart.current = createChart(chartContainerRef.current, {
      height: 240,
      autoSize: true,
      layout: {
        background: {
          type: "solid",
          color: 'white',
        },
        textColor: 'black',
      },
      grid: {
        vertLines: {
          color: '#334158',
        },
        horzLines: {
          color: '#334158',
        },
      },
      crosshair: {
        mode: CrosshairMode.Normal,
      },
      localization: {
        dateFormat : 'yyyy-MM-dd',
      },
      priceScale: {
        borderColor: '#485c7b',
      },
      timeScale: {
        borderColor: '#485c7b',
      },
      rightPriceScale: {
        visible: true,
      },
      leftPriceScale: {
        visible: true,
      },
    });

    const candleSeries = chart.current.addCandlestickSeries({
      upColor: 'red',
      downColor: 'blue',
      borderUpColor: 'red',
      borderDownColor: 'blue',
      wickUpColor: 'red',
      wickDownColor: 'blue',
      priceScaleId: 'right',
      priceFormat: {
        type: "price",
        minMove: 1,
      }
    });

    // const areaSeries = chart.current.addAreaSeries({
    //   topColor: 'rgba(38,198,218, 0.56)',
    //   bottomColor: 'rgba(38,198,218, 0.04)',
    //   lineColor: 'rgba(38,198,218, 1)',
    //   lineWidth: 2
    // });

    // areaSeries.setData(areaData);

    const volumeSeries = chart.current.addHistogramSeries({
      color: 'black',
      priceFormat: {
        type: 'price',
        minMove: 0.1,
      },
      priceScaleId: 'left',
    });
    volumeSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.8,
        bottom: 0,
      },
    })

    candleSeries.setData(candlesToPrice(si.candles));
    volumeSeries.setData(candlesToValue(si.candles));

    tooltip.current = document.createElement('div');
    tooltip.current.className = styles.tooltip;
    tooltip.current.style.background = "rgba(255, 255, 255, 0.8)";
    tooltip.current.style.color = "black";
    tooltip.current.style.border = '1px solid rgba( 38, 166, 154, 1)';
    chartContainerRef.current.appendChild(tooltip.current);

    const handleCrosshairMove = (param) => {
      if (param.time && [param.seriesData].length && tooltip.current) {
        // param에서 data 꺼내기
        // seriesData는 Map 형식이기 때문에 각각 설정할 때 생성해두었던 변수로 get
        const candle = param.seriesData.get(candleSeries);
        const volume = param.seriesData.get(volumeSeries);

        const toolTipDiv = `<div>
          <p>${candle.time}</p>
          <table>
            ${makeTablePriceString("시", "black", candle.open, candle.customValues.prev)}
            ${makeTablePriceString("고", "red", candle.high, candle.customValues.prev)}
            ${makeTablePriceString("저", "blue", candle.low, candle.customValues.prev)}
            ${makeTablePriceString("종", "black", candle.close, candle.customValues.prev)}
            <tr>
              <td>대</td>
              <td colspan='2'>${volume.value.toFixed(1)} 억</td>
            </tr>
          </table>
        </div>`

        /*
<div>종가: ${candle.close} (${makePercent(candle.close, candle.customValues.prev)}%)</div>
          <div>시가: ${candle.open} (${makePercent(candle.open, candle.customValues.prev)}%)</div>
          <div>고가: ${candle.high} (${makePercent(candle.high, candle.customValues.prev)}%)</div>
          <div>저가: ${candle.low} (${makePercent(candle.low, candle.customValues.prev)}%)</div>
          <div>대금: ${volume.value.toFixed(1)} 억</div>
        */

        // Update the tooltip content with the time and prices
        tooltip.current.innerHTML = toolTipDiv;

        // Position the tooltip relative to the chart
        if (!!param.sourceEvent && !!param.sourceEvent.pageX && !!param.sourceEvent.pageY) {
          tooltip.current.style.left = `${param.sourceEvent.pageX}px`;
          tooltip.current.style.top = `${param.sourceEvent.pageY}px`;
          
          // Show the tooltip
          tooltip.current.style.display = 'block';
        }
      } else {
        // Hide the tooltip if no valid data point is available
        if (tooltip.current) {
          tooltip.current.style.display = 'none';
        }
      }
    };

    chart.current.subscribeCrosshairMove(handleCrosshairMove);

    const updateCandle = (si_id, candle) => {
      if (si_id !== si.id) return;

      console.log(si_id)

      si.candles[si.candles.length - 1] = candle;
      candleSeries.setData(candlesToPrice(si.candles));
      volumeSeries.setData(candlesToValue(si.candles));
    }
    socket.on("candle", updateCandle);

    return () => {
      socket.off("candle", updateCandle)
      if (chart.current) {
        chart.current.remove();
        chart.current = null;
      }
      if (tooltip.current) {
        tooltip.current.remove();
        tooltip.current = null;
      }
    }
  }, [si]);

  return (
    <div ref={chartContainerRef} />
  );
}