function makeHeader() {
  return {
    "Content-Type": "application/json",
    "Authorization": `Token ${localStorage.getItem("token")}`
  }
}

export const BACKEND_API_URL = `${process.env.REACT_APP_BACKEND_URL}/api`

export async function fetchCaptures(exclude_ids = [], include_ids = []) {
  const exclude_query = exclude_ids.map(eid => `exclude_ids=${eid}`).join('&')
  const include_query = include_ids.map(iid => `include_ids=${iid}`).join('&')
  return fetch(`${BACKEND_API_URL}/themes/capture/?${exclude_query}&${include_query}`, {
    method: "GET",
    headers: makeHeader(),
  })
    .then(response => {
      if (response.status !== 200) throw new Error("테마 캡쳐를 불러오는데 실패했습니다.")
      return response.json()
    })
}

async function orderStock(si_id, result_id, is_buy, type, price) {
  return fetch(`${BACKEND_API_URL}/themes/order/`, {
    method: "POST",
    headers: makeHeader(),
    body: JSON.stringify({si_id, result_id, is_buy, type, price})
  }).then(
    response => {
      if (response.status !== 200) throw new Error("주식 주문에 실패했습니다.")
      return response.json()
    }
  )
}

export async function buyStock(si_id, type, price) {
  return orderStock(si_id, -1, true, type, price)
}

export async function sellStock(result_id, type, price) {
  return orderStock(-1, result_id, false, type, price)
}

export async function cancelStockOrder(id) {
  return fetch(`${BACKEND_API_URL}/themes/order/${id}/`, {
    method: "DELETE",
    headers: makeHeader(),
  }).then(
    response => {
      if (response.status !== 200) throw new Error("주문 취소에 실패했습니다.")
      return response.json()
    }
  )
}

export async function fetchResult(id) {
  return fetch(`${BACKEND_API_URL}/themes/result/${id}/`, {
    method: "GET",
    headers: makeHeader(),
  })
    .then(response => {
      if (response.status !== 200) throw new Error("매매 결과를 불러오는데 실패했습니다.")
      return response.json()
    })
}

export async function fetchResults(start_date, end_date) {
  return fetch(`${BACKEND_API_URL}/themes/result/?start_date=${start_date}&end_date=${end_date}`, {
    method: "GET",
    headers: makeHeader(),
  })
    .then(response => {
      if (response.status !== 200) throw new Error("매매 결과를 불러오는데 실패했습니다.")
      return response.json()
    })
}

export async function fetchStockIndex(si_id, exclude_candles = false) {
  return fetch(`${BACKEND_API_URL}/themes/stock/${si_id}/?exclude_candles=${exclude_candles}`, {
    method: "GET",
    headers: makeHeader(),
  })
    .then(response => {
      if (response.status !== 200) throw new Error("주식 정보를 불러오는데 실패했습니다.")
      return response.json()
  })
}

export async function fetchSearches() {
  return fetch(`${BACKEND_API_URL}/themes/search/`, {
    method: "GET",
    headers: makeHeader(),
  })
    .then(response => {
      if (response.status !== 200) throw new Error("검색 결과 정보를 불러오는데 실패했습니다.")
      return response.json()
    })
}