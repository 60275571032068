function makeTimeFormat(dt) {
  return dt.slice(11, 16)
}

function textPriority(text, priority, isInTheme) {
  if (priority === '2') {
    return <b style={{color: "red"}}>{text}</b>
  }

  if (isInTheme) {
    return <b style={{color: "purple"}}>{text}</b>
  }

  if (priority === '0') {
    return <span style={{color: '#BBBBBB'}}>{text}</span>
  }
  return <span>{text}</span>
}


export default function SearchTableRow({search, onChangeHandler, onClickButtonHandler, isInTheme}) {
  return (
    <tr>
      <td>{textPriority(`${search.name} (${search.code})`, search.priority, isInTheme)}</td>
      <td className="has-text-centered">{textPriority(makeTimeFormat(search.min_dt), search.priority, isInTheme)}</td>
      <td className="has-text-centered">{textPriority(makeTimeFormat(search.max_dt), search.priority, isInTheme)}</td>
      <td className="has-text-centered">{textPriority(search.count, search.priority, isInTheme)}</td>
      <td className="has-text-centered">
        <div className="select is-small">
          <select defaultValue={search.priority || '1'} onChange={e => onChangeHandler(search.si_id, e.target.value)}>
            <option value="2">관심</option>
            <option value="1">일반</option>
            <option value="0">제외</option>
          </select>
        </div>
      </td>
      <td><button className="button is-danger is-small" onClick={() => onClickButtonHandler(search.si_id)}>매수</button></td>
    </tr>
  )
}