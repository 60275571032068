import ConfirmModal from "./ConfirmModal";
import { useCallback } from "react";
import { useRecoilState, atom } from "recoil";
import { fetchStockIndex, fetchResult, buyStock, sellStock, cancelStockOrder } from "./apis";

const modalState = atom({
  key: 'tradeModalState',
  default: {
    isBuy: false,
    isOpen: false,
    si: null,
    result: null,
    price: 0,
    type: 0,
    isPendingOnly: false,
  }
});

export function useModal() {
  const [modalDataState, setModalDataState] = useRecoilState(modalState);

  const closeModal = useCallback(
    () =>
      setModalDataState(() => {
        return {
          isBuy: false,
          isOpen: false,
          si: null,
          result: null,
          price: null,
          type: 0,
          isPendingOnly: false,
        };
      }),
    [setModalDataState]
  );

  const openBuyModal = useCallback(
    (si_id) => {
      fetchStockIndex(si_id, true)
        .then(si => {
          setModalDataState({
            isBuy: true,
            isOpen: true,
            si: si,
            result: null,
            price: null,
            type: 0,
            isPendingOnly: false,
          })
        })
        .catch(err => alert(err))
    },
    [setModalDataState]
  )

  const openSellModal = useCallback(
    (result_id, isPendingOnly = false) => {
      fetchResult(result_id)
        .then(result => {
          setModalDataState({
            isBuy: false,
            isOpen: true,
            si: null,
            result: result,
            price: null,
            type: isPendingOnly ? 1 : 0,
            isPendingOnly: isPendingOnly,
          })
        })
        .catch(err => alert(err))
    },
    [setModalDataState]
  )

  const setType = type => {
    setModalDataState({
      ...modalDataState,
      type: type,
    })
  }

  const setPrice = price => {
    setModalDataState({
      ...modalDataState,
      price: price,
    })
  }

  const setResult = result => {
    console.log(result)
    setModalDataState({
      ...modalDataState,
      result: result,
    })
  }

  const closeModalIfMatch = result_id => {
    console.log(`Current modal result: ${modalDataState.result}`)
    if (!!modalDataState.result && modalDataState.result.id === result_id) {
      closeModal()
    }
  }

  return { modalDataState, closeModal, closeModalIfMatch, openBuyModal, openSellModal, setType, setPrice, setResult };
}

function buttonClassNames(isSelected) {
  return isSelected ? "button is-focused" : "button"
}

function isValidPrice(modalDataState) {
  const price = parseInt(modalDataState.price)
  if (modalDataState.type === 1 && !(!isNaN(modalDataState.price) && !isNaN(price) && price > 0)) {
    return false
  }
  return true
}


const PriceType = {
  MARKET: 0,
  PENDING: 1,
  MINUTE_LOSS: 2,
}

Object.freeze(PriceType)

function isSupportPrice(type) {
  return type !== PriceType.MARKET
}

function priceTypeToKor(type) {
  switch (type) {
    case PriceType.MARKET:
      return "시장가"
    case PriceType.PENDING:
      return "지정가"
    case PriceType.MINUTE_LOSS:
      return "분봉손절"
    default:
      return "???"
  }
}


function PriceTypeButtons({modalDataState, setType, availableTypes}) {
  return (
    <div className="buttons is-centered">
      {availableTypes.map(priceType => {
        return (
          <button 
            key={priceType} 
            onClick={() => setType(priceType)}
            className={buttonClassNames(modalDataState.type === priceType)}
            disabled={modalDataState.isPendingOnly && priceType === PriceType.MARKET}>
              {priceTypeToKor(priceType)}
          </button>
        )
      })}
    </div>
  )
}

function InputPrice({modalDataState, setPrice}) {
  return (
    <input 
      className="input"
      value={isSupportPrice(modalDataState.type) ? modalDataState.price || "" : ""}
      onInput={e => setPrice(e.target.value)}
      disabled={modalDataState.type === PriceType.MARKET}
      autoFocus />
  )
}

function BuyModal({modalDataState, updateResults, closeModal, setType, setPrice}) {
  console.log(modalDataState)

  return (
    <ConfirmModal
      modalState={modalDataState}
      confirmModal={() => {
        if (!isValidPrice(modalDataState)) {
          alert("가격은 0 보다 커야 합니다")
          return
        }

        buyStock(modalDataState.si.id, modalDataState.type, modalDataState.price || 0)
        .then(() => {
          updateResults()
          return closeModal()
        })
        .catch(err => alert(err))
      }}
      closeModal={closeModal}
      buttonMessage="매수">
      <div className="is-flex is-flex-direction-column is-align-items-center">
        <p className="is-size-3">{modalDataState.si.stock_name}</p>
        <p className="is-size-6">{modalDataState.si.stock_code}</p>

        <div className="columns mt-5 mb-5">
          <div className="column has-text-centered">
            <PriceTypeButtons modalDataState={modalDataState} setType={setType} availableTypes={[PriceType.MARKET, PriceType.PENDING]}/>
            <InputPrice modalDataState={modalDataState} setPrice={setPrice}/>
          </div>
        </div>
      </div>
    </ConfirmModal>
  )
}

function makeTimeFormat(dt) {
  return dt.slice(11, 19)
}

function SellTableRow({orderData, onClickCancel}) {
  return <tr>
    <td>{priceTypeToKor(orderData.type)}</td>
    <td>{orderData.price}</td>
    <td>{makeTimeFormat(orderData.dt)}</td>
    <td><button className="button is-warning is-small" onClick={() => onClickCancel(orderData.id)}>취소</button></td>
  </tr>
}

function SellTable({modalDataState, onClickCancel}) {
  const orders = modalDataState.result.orders

  const rows = orders.length === 0 ? 
    <tr>
      <td colSpan="4" className="has-text-centered">주문 내역이 없습니다</td>
    </tr> : 
    orders.map(order => <SellTableRow key={order.id} orderData={order} onClickCancel={onClickCancel}/>)

  return <table className="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th className="has-text-centered">방식</th>
          <th className="has-text-centered">가격</th>
          <th className="has-text-centered">주문시각</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
}


function SellModal({modalDataState, updateResults, closeModal, setType, setPrice, setResult}) {
  const _update = useCallback(result_id => {
    updateResults()
    fetchResult(result_id)
      .then(result => {
        setResult(result)
        if (!!result.is_executed) {
          closeModal()
        }
      })
    }, [updateResults, setResult, closeModal]
  )

  const onClickCancel = useCallback((id) => {
    cancelStockOrder(id)
      .then(() => _update(modalDataState.result.id))
  }, [updateResults, modalDataState, _update])

  return (
    <ConfirmModal
      modalState={modalDataState}
      confirmModal={() => {
        if (!isValidPrice(modalDataState)) {
          alert("가격은 0 보다 커야 합니다")
          return
        }

        sellStock(modalDataState.result.id, modalDataState.type, modalDataState.price || 0)
        .then(() => {
          _update(modalDataState.result.id)
        })
        .catch(err => alert(err))
      }}
      closeModal={closeModal}
      buttonMessage="매도">
      <div className="is-flex is-flex-direction-column is-align-items-center">
        <p className="is-size-3">{modalDataState.result.stock_name}</p>
        <p className="is-size-6">{modalDataState.result.stock_code}</p>

        <div className="columns mt-5 mb-5">
          <div className="column has-text-centered">
            <PriceTypeButtons modalDataState={modalDataState} setType={setType} availableTypes={[PriceType.MARKET, PriceType.PENDING, PriceType.MINUTE_LOSS]}/>
            <InputPrice modalDataState={modalDataState} setPrice={setPrice}/>
            <div style={{maxHeight: "30vh", overflowY: "auto"}} className="mt-5">
              <SellTable modalDataState={modalDataState} onClickCancel={onClickCancel}/>
            </div>
          </div>
        </div>
      </div>
    </ConfirmModal>
  )
}

export default function TradeModal({ updateResults }) {
  const { modalDataState, closeModal, setType, setPrice, setResult } = useModal();

  if (!modalDataState.isOpen) return null

  if (modalDataState.isBuy) {
    if (!modalDataState.si) return null

    return <BuyModal modalDataState={modalDataState} updateResults={updateResults} closeModal={closeModal} setType={setType} setPrice={setPrice} />
  } else {
    if (!modalDataState.result) return null

    return <SellModal modalDataState={modalDataState} updateResults={updateResults} closeModal={closeModal} setType={setType} setPrice={setPrice} setResult={setResult}/>
  }

  /*
  if (!modalDataState.si) return null

  if (!modalDataState.isBuy && !modalDataState.ri) return null

  let confirmModal = modalDataState.isBuy ? confirmBuy : confirmSell

  return (
    <ConfirmModal
      modalState={modalState}
      confirmModal={() => {
        console.log(modalDataState)
        const price = parseInt(modalDataState.price)
        if (modalDataState.type === 1 && !(!isNaN(modalDataState.price) && !isNaN(price) && price > 0)) {
          alert("가격은 0 보다 커야 합니다")
          return
        }
        confirmModal(modalDataState).then(response => {
          if (response.status === 200) {
            updateResults()
            return closeModal()
          }
          throw new Error("주문에 실패했습니다.")
        })
          .catch(error => {
            alert("주문에 실패했습니다.");
            console.error(error);
          })
      }}
      closeModal={closeModal}
      buttonMessage={modalDataState.isBuy ? "매수" : "매도"}>
      <div className="is-flex is-flex-direction-column is-align-items-center">
        <p className="is-size-3">{modalDataState.si.stock_name}</p>
        <p className="is-size-6">{modalDataState.si.stock_code}</p>

        <div className="columns mt-5">
          <div className="column has-text-centered">
            <div className="buttons">
              <button onClick={() => setType(0)} className={buttonClassNames(modalDataState.type === 0)} disabled={modalDataState.isDisableMarket}>시장가</button>
              <button onClick={() => setType(1)} className={buttonClassNames(modalDataState.type === 1)}>지정가</button>
              <button onClick={() => setType(2)} className={buttonClassNames(modalDataState.type === 2)}>종가예약</button>
            </div>
            <input className="input" value={modalDataState.type === 1 ? modalDataState.price || "" : ""}
              onInput={e => setPrice(e.target.value)} disabled={modalDataState.type !== 1} autoFocus />
          </div>
        </div>

        <p className="is-size-6 pb-3">이 결정은 취소할 수 없습니다.</p>
      </div>
    </ConfirmModal>
  )
  */
}