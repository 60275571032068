import ResultTable from './ResultTable';

export default function ResultColumn({ updateResults, results }) {

  return (
    <div className="is-flex is-flex-direction-column" style={{height: "100%"}}>
      <div className="is-flex-grow-1" style={{overflowY: "scroll", height: "100%"}}>
        <ResultTable updateResults={updateResults} results={results}/>
      </div>

    </div>
  )
}