import SearchTable from './SearchTable'

export default function SearchColumn({ searches, themes, onChangeHandler }) {

  const theme_si_set = new Set([].concat(...themes.map(theme => (theme.stocks || []).map(stock => stock.si_id))))

  return (
    <div style={{height: "100%", width: "100%"}}>
      <div style={{overflowY: "scroll", height: "100%"}}>
        <SearchTable searches={searches} onChangeHandler={onChangeHandler} theme_si_set={theme_si_set}/>
      </div>
    </div>
  )
}