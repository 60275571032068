import { useCallback } from "react";
import ResultTableRow from "./ResultTableRow"
import { useModal } from "../TradeModal"
import { cancelStockOrder } from "../apis";


export default function ResultTable({ results, updateResults }) {
  const { openSellModal } = useModal();

  const onClickCancel = useCallback((id) => {
    cancelStockOrder(id)
      .then(() => updateResults())
  }, [updateResults])

  const rows = results.map(result => (
    <ResultTableRow 
      key={result.result_id}
      result={result}
      onClickButtonHandler={openSellModal}
      onClickCancelHandler={onClickCancel}
    />
  ))
  return (
    <table className="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th>종목</th>
          <th>매수</th>
          <th>매도</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  )
}