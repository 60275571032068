import {atom} from 'recoil';
import {BACKEND_API_URL} from "./apis.js";

export const userState = atom({
    key: "user",
    default: {
        username: localStorage.getItem("username"),
    }
})

export const isLoggedInState = atom({
    key: "isLoggedIn",
    default: localStorage.getItem("token") ? true : false
})

export const login = async ({username, password}) => {
    const response = await fetch(`${BACKEND_API_URL}/users/login/`, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({username, password})
    })
    if (response.status !== 200) throw new Error("로그인에 실패했습니다.")

    const token = await response.json()
    localStorage.setItem("token", token)
    localStorage.setItem("username", username)

    return {username, token}
}

export const logout = () => {
    localStorage.removeItem("token")
    localStorage.removeItem("username")
}
