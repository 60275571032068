import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import { userState, isLoggedInState, login } from './auth';


export default function Login() {
  const [formData, setFormData] = useState({ username: '', password: '' });
  const setUser = useSetRecoilState(userState);
  const setIsLoggedIn = useSetRecoilState(isLoggedInState);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { username } = await login(formData);
      setUser({ username });
      setIsLoggedIn(true);
      navigate('/');
    } catch (error) {
      alert("로그인에 실패했습니다.");
    }
  }

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div className="container">
      <div className="columns is-centered">
        <div className="column is-5-tablet is-4-desktop is-3-widescreen">
          <form className="box mt-6" onSubmit={handleSubmit}>
            <div className="field">
              <label className="label">ID</label>
              <div className="control">
                <input className="input" type="text" name="username" value={formData.username} onChange={handleChange}/>
              </div>
            </div>

            <div className="field">
              <label className="label">Password</label>
              <div className="control">
                <input className="input" type="password" name="password" value={formData.password} onChange={handleChange}/>
              </div>
            </div>

            <button type="submit" className="button is-primary">로그인</button>
          </form>
        </div>
      </div>
    </div>
  )
}